(function(){

	'use strict';

	angular.module('3wks.stopPropagation', [])
		.directive('stopPropagation', [ function () {

			function link(scope, element, attrs) {
				element.on("click", function(event) {
					event.stopPropagation();
				});
			}

			return {
				link: link
			};

		}]);
})();